import { TeaserGridSection } from 'components/UI/TeaserGridSectionNew';
import { TeaserNew } from 'components/UI/TeaserNew';
import type { TitleLink } from 'components/UI/Title/Title';
import type { WidgetTeaserItemProps } from 'types/cms-teaser';

export type WidgetTeaserSmallAufmacherProps = {
  readonly model?: {
    readonly fields?: {
      readonly title?: string;
      readonly link?: TitleLink;
      readonly anchor?: string;
      readonly firstTeaserHighlight?: boolean;
    };
  };
  readonly items?: ReadonlyArray<WidgetTeaserItemProps>;
};

const WidgetTeaserSmallAufmacher = ({ context }: { context: WidgetTeaserSmallAufmacherProps }) => {
  const width = [1200, 375];

  return (
    <TeaserGridSection
      variant="small-aufmacher"
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      {context.items?.map((item, index) => (
        <TeaserNew
          key={item.model.id}
          variant={index === 0 ? 'large' : 'small'}
          title={item.model.fields.title}
          subtitle={index === 0 ? item.model.themenwelt?.section_name : undefined}
          image={{
            image: item.model.image,
            width: index === 0 ? width[0] : width[1],
            alt: `Bild zu: "${item.model.fields.title}"`,
          }}
          linkHref={item.model.fields?.externalUrl ?? item.model.url}
          subtitleColor={item.model.themenwelt?.secondary_color}
          isHighlight={context.model?.fields?.firstTeaserHighlight && index === 0}
          isVideo={!!item.model.fields.encoding?.[0]?.value?.duration?.value}
          videoDuration={Number(item.model.fields.encoding?.[0]?.value?.duration?.value)}
          isSponsored={item.type === 'sponsored-article'}
          openLinkInNewTab={!!item.model.fields?.externalUrl}
        />
      ))}
    </TeaserGridSection>
  );
};

export default WidgetTeaserSmallAufmacher;
